// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timer {
  padding: 0 1.4rem;
  border: 1px solid var(--font-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius);
  margin: 0 auto;
  height: 3rem;
  width: 7rem;
  font-size: 2rem;
  text-align: center;
  background-color: var(--secondary-background);
  transform: translateY(-5rem);
  transition: transform 0.3s var(--bounce-effect);
  justify-content: space-evenly;
}

.playerRoom .timer {
  height: 2rem;
  font-size: 1.8rem;
}

.timer.go:not(.hide) {
  transform: translateY(0);
}

.pause {
  display: flex;
  justify-content: center;
  border: 2px solid var(--font-border-color);
  font-size: 2rem;
  line-height: 2rem;
  font-weight: bold;
  height: 2rem;
  width: 2rem;
  vertical-align: baseline;
  box-sizing: border-box;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/browser/webapp/components/room/shared/timer.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,mCAAmC;EACnC,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mCAAmC;EACnC,cAAc;EACd,YAAY;EACZ,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,6CAA6C;EAC7C,4BAA4B;EAC5B,+CAA+C;EAC/C,6BAA6B;AAC/B;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,0CAA0C;EAC1C,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;EACZ,WAAW;EACX,wBAAwB;EACxB,sBAAsB;EACtB,eAAe;AACjB","sourcesContent":[".timer {\n  padding: 0 1.4rem;\n  border: 1px solid var(--font-color);\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-radius: var(--border-radius);\n  margin: 0 auto;\n  height: 3rem;\n  width: 7rem;\n  font-size: 2rem;\n  text-align: center;\n  background-color: var(--secondary-background);\n  transform: translateY(-5rem);\n  transition: transform 0.3s var(--bounce-effect);\n  justify-content: space-evenly;\n}\n\n.playerRoom .timer {\n  height: 2rem;\n  font-size: 1.8rem;\n}\n\n.timer.go:not(.hide) {\n  transform: translateY(0);\n}\n\n.pause {\n  display: flex;\n  justify-content: center;\n  border: 2px solid var(--font-border-color);\n  font-size: 2rem;\n  line-height: 2rem;\n  font-weight: bold;\n  height: 2rem;\n  width: 2rem;\n  vertical-align: baseline;\n  box-sizing: border-box;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
