import React, {
  Children,
  isValidElement,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import './marquee.css';

const INIT_DELAY = 100;
const ANIMATION_DELAY = 300;

type MarqueeItemProps = {
  duration: number;
  children: ReactNode;
}

export const MarqueeItem = ({ children }: MarqueeItemProps) => {
  return <>{children}</>;
};

type WrappedItemProps = MarqueeItemProps & {
  onComplete: () => void;
}

const WrappedItem = ({ onComplete, children, duration }: WrappedItemProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const initTimeout = setTimeout(() => {
      setIsActive(true);
      timeout = setTimeout(() => {
        setIsActive(false);
        setTimeout(onComplete, ANIMATION_DELAY);
      }, Math.max(duration - INIT_DELAY, 0));
    }, INIT_DELAY);

    return () => {
      clearTimeout(initTimeout);
      clearTimeout(timeout);
    };
  }, []);

  return <div className={'marqueeItem' + (isActive ? ' active' : '')}>
    {children}
  </div>;
};

const isMarqueeItem = (element: ReactNode): element is ReactElement<MarqueeItemProps> => {
  return isValidElement(element) && element.type === MarqueeItem;
};

/*
const isFragment = (element: ReactNode): element is ReactElement => {
  return isValidElement(element) && element.type === React.Fragment;
};
*/

const isFragment = (element: ReactNode): element is ReactElement & { props: { children: ReactNode } } => {
  return isValidElement(element) && element.type === React.Fragment && (element.props as { children?: ReactNode }).children !== undefined;
};

type MarqueeProps = {
  onComplete: () => void;
  children: ReactNode;
};

export const Marquee = ({ onComplete, children }: MarqueeProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [curItem, setCurItem] = useState<number>(0);

  useEffect(() => {
    if (containerRef.current) {
      setTimeout(() => {
        containerRef.current?.classList.add('active');
      }, 0);
    }
  }, [containerRef.current]);

  const wrappedChildren = useMemo(() => {
    const onComplete = (i: number) => {
      setCurItem(i + 1);
    };

    let i = 0;
    const flatten = (children: ReactNode) => {
      const acc: ReactNode[] = [];
      Children.forEach(children, (c) => {
        if (isMarqueeItem(c)) {
          acc.push(
            <WrappedItem key={`wrapped-marquee-${i}`} {...(c.props)} onComplete={onComplete.bind(null, i)}>
              {c.props.children}
            </WrappedItem>
          );
          ++i;
        } else if (isFragment(c)) {
          acc.push(...flatten(c.props.children));
        }
      });
      return acc;
    };

    return flatten(children);
  }, [children]);

  useEffect(() => {
    if (wrappedChildren && curItem === wrappedChildren.length) {
      containerRef.current?.classList.remove('active');
      setTimeout(onComplete, ANIMATION_DELAY);
    }
  }, [curItem, wrappedChildren]);

  if (!wrappedChildren) {
    return null;
  }

  return (
    <div ref={containerRef} className='marqueeContainer'>
      {wrappedChildren[curItem]}
    </div>
  );
};
