// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marqueeContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: transparent;
  transition: background-color 0.25s linear;
  overflow: hidden;
}

.marqueeContainer .marqueeItem,
.marqueeContainer .marqueeItem p {
  font-size: 4rem;
}

.marqueeContainer.active {
  background-color: rgba(0,0,0, 0.6);
}

.marqueeContainer .marqueeItem {
  box-sizing: border-box;
  position: relative;
  border-radius: 10px;
  padding: 1rem 2rem 0.8rem;
  background-color: var(--primary-background);
  transform: scale(0);
  transition: transform 0.07s linear;
  text-align: center;
  color: var(--secondary-color);
  text-shadow: var(--outline-text-shadow);
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
}

.marqueeItem.active {
  transform: scale(1);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.marqueeItem > * {
  margin: 0;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/browser/webapp/components/room/shared/marquee.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,6BAA6B;EAC7B,yCAAyC;EACzC,gBAAgB;AAClB;;AAEA;;EAEE,eAAe;AACjB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,yBAAyB;EACzB,2CAA2C;EAC3C,mBAAmB;EACnB,kCAAkC;EAClC,kBAAkB;EAClB,6BAA6B;EAC7B,uCAAuC;EACvC,oCAAoC;EACpC,wCAAwC;AAC1C;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,mEAAmE;AACrE;;AAEA;EACE,SAAS;EACT,UAAU;AACZ","sourcesContent":[".marqueeContainer {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  margin: 0 auto;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 10;\n  background-color: transparent;\n  transition: background-color 0.25s linear;\n  overflow: hidden;\n}\n\n.marqueeContainer .marqueeItem,\n.marqueeContainer .marqueeItem p {\n  font-size: 4rem;\n}\n\n.marqueeContainer.active {\n  background-color: rgba(0,0,0, 0.6);\n}\n\n.marqueeContainer .marqueeItem {\n  box-sizing: border-box;\n  position: relative;\n  border-radius: 10px;\n  padding: 1rem 2rem 0.8rem;\n  background-color: var(--primary-background);\n  transform: scale(0);\n  transition: transform 0.07s linear;\n  text-align: center;\n  color: var(--secondary-color);\n  text-shadow: var(--outline-text-shadow);\n  border: 1px solid rgba(0, 0, 0, 0.3);\n  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);\n}\n\n.marqueeItem.active {\n  transform: scale(1);\n  transition-duration: 0.2s;\n  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);\n}\n\n.marqueeItem > * {\n  margin: 0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
