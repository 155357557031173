import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';

import {
  RoomResult,
  KitchenSinkResult
} from '@shared/api-dto';
import { getKitchenSink } from './endpoints';
import { NetErrorType } from '@shared/constants';

const MAX_RETRIES = 5;
const RECONNECT_DELAY = 200;

export type ApiHookResult<T> = [
  boolean, // isLoading
  React.ReactNode | string | null, // errorMessage
  T | null // apiResponse
];

export function useKitchenSink (): ApiHookResult<KitchenSinkResult> {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);
  const [kitchenSink, setKitchenSink] = React.useState<KitchenSinkResult | null>(null);

  React.useEffect(() => {
    (async () => {
      try {
        const result = await getKitchenSink();
        setIsLoading(false);
        setKitchenSink(result);
      } catch (e: any) {
        setIsLoading(false);
        setError(e);
      }
    })();
  }, []);

  return [isLoading, error, kitchenSink];
}

export function useRoomData (code?: string): ApiHookResult<RoomResult> {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);
  const [roomData, setRoomData] = React.useState<RoomResult | null>(null);

  React.useEffect(() => {
    if (!code) {
      setError('No code found');
      return;
    }

    let retries = 0;

    let socket: WebSocket;
    const connect = () => {
      try {
        setIsLoading(true);
        socket = new WebSocket(`${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//${window.location.host}/ws/join/${code}`);

        socket.onopen = () => {
          retries = 0;
          setError(null);
        };

        socket.onmessage = (evt: MessageEvent) => {
          try {
            const result = JSON.parse(evt.data);
            if (result?.error) {
              if (result.error.type === NetErrorType.KICKED) {
                setError('Sorry, you have been removed from the room.');
                setRoomData(null);
              } else {
                setError('An unknown error occurred, please try again later.');
              }
              return;
            }
            setRoomData(result);
            setIsLoading(false);
          } catch (e) {
            console.error('Unable to process room update', e);
          }
        };

        socket.onerror = (err) => {
          console.error('WebSocket error', err);
          setError('Connection failed, attempting to reconnect...');
          socket.close();
        };

        socket.onclose = (evt: CloseEvent) => {
          if (evt.code === 1008 && evt.reason) {
            setError('Connection failed: ' + evt.reason);
            setIsLoading(false);
          } else if (retries <= MAX_RETRIES) {
            setTimeout(() => {
              ++retries;
              connect();
            }, RECONNECT_DELAY * retries);
          } else {
            setError('Connection failed, please refresh page to try again.');
            setIsLoading(false);
          }
        };
      } catch (e: any) {
        console.error('WebSocket connection error', e);
        setError('Connection failed, please refresh page to try again.');
        setIsLoading(false);
      }
    };

    connect();

    return () => {
      if (socket) {
        socket.onclose = null;
        socket.close();
      }
    };
  }, [code]);

  return [isLoading, error, roomData];
}

export function useQRCode (code: string) {
  const [dataUrl, setDataUrl] = useState<string | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  useEffect(() => {
    (async function () {
      try {
        const url = new URL(`/room/${code}`, window.origin);
        const data = await QRCode.toDataURL(url.toString(), {
          width: 256
        });
        setErrorMsg(null);
        setDataUrl(data);
      } catch (e: any) {
        setDataUrl(null);
        setErrorMsg(e.message ?? 'Failed to QR encode url');
      }
    })();
  }, [code]);

  return [errorMsg, dataUrl];
}
