import React, { useRef, useEffect } from 'react';
import { RoomResult, PlayerResult } from '@shared/api-dto';
import { PromptFormat } from '@shared/constants';
import { getNextPrompt, getTeamName, getTeamUp, getRoundName, quote } from '../shared/room-data-helpers';
import { Marquee, MarqueeItem } from '../shared/marquee';

type StartMoveMarqueeProps = {
  roomData: RoomResult;
  onComplete: () => void;
  nextPlayer?: PlayerResult;
  nextOpponent?: PlayerResult;
};

export const StartMoveMarquee = ({
  roomData,
  nextPlayer,
  onComplete,
  nextOpponent,
}: StartMoveMarqueeProps) => {
  if (roomData.currentMove) {
    return null;
  }

  const teamRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (teamRef.current) {
      setTimeout(() => {
        teamRef.current!.classList.add('active');
      }, 1400);
    }
  }, [teamRef.current]);

  const move = roomData.move;
  const round = Math.ceil(move / 2);
  const teamUp = getTeamUp(roomData);
  const teamName = getTeamName(teamUp);
  const nextPrompt = getNextPrompt(roomData);

  return (
    <Marquee key={'marquee_intro' + roomData.move} onComplete={onComplete}>
      {move === 1 && (
        <>
          <MarqueeItem key='pick_teams' duration={4000}>
            <div ref={teamRef} className='chooseTeams'>
              <div className='teamPanel team1'></div>
              <div className='teamPanel team2'></div>
              <div className='playerHolder'>
                <div>Picking teams...</div>
                {roomData.players.map(p => (
                  <div key={p.user.guid}>
                    <span className={p.team === 1 ? 'onTeam1' : 'onTeam2'}>
                      {p.user.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </MarqueeItem>
          <MarqueeItem key='get_ready' duration={1500}>
            {'Let\'s go!'}
          </MarqueeItem>
        </>
      )}
      {teamUp === 1 && (
        <>
          <MarqueeItem key='marquee_round' duration={1500}>{`Round ${round}`}</MarqueeItem>
          <MarqueeItem duration={1500}>
            <div style={{ fontSize: '6rem' }}>{getRoundName(roomData)}</div>
          </MarqueeItem>
        </>
      )}
      {nextPrompt.type === PromptFormat.MULTIPLE_CHOICE && (
        <MarqueeItem key='marquee_team_up' duration={1500}>
          {`The ${teamName} team is up`}
        </MarqueeItem>
      )}
      {nextPrompt.type === PromptFormat.FREE_INPUT
        ? (
          <>
            <MarqueeItem key='player_up' duration={1750}>
              {`${nextPlayer?.user.name}'s turn!`}
            </MarqueeItem>
            <MarqueeItem key='free_input' duration={4000}>
              <p>
                What did <b className='big'>{nextOpponent?.user.name}</b> say when asked:
              </p>
              <p className='actualAnswer'>
                <i>{quote(nextPrompt.text)}</i>
              </p>
            </MarqueeItem>
          </>
          )
        : (
          <MarqueeItem key='non_free_input' duration={2000}>
            {`Get ready ${nextPlayer?.user.name ?? 'everybody'}!`}
          </MarqueeItem>
          )}
      <MarqueeItem duration={500}>Ready?</MarqueeItem>
      <MarqueeItem duration={500}>STEADY!</MarqueeItem>
      <MarqueeItem duration={800}>GO!</MarqueeItem>
    </Marquee>
  );
};