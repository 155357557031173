import React, { useState, useMemo, useEffect, useRef } from 'react';
import {
  MoveDurationByFormat,
  MoveType,
  PromptFormat,
} from '@shared/constants';
import { ActivateFactOrFibData, PlayerResult, RoomResult } from '@shared/api-dto';
import {
  allPlayersResponded,
  getFactOrFibAnswer,
  getNextPrompt,
  getTeamName,
  getTeamUp,
  isAllPlay,
  isGameOver,
  isPreMove,
  moveWasSubmitted,
  teamIsMoving,
  useFactOrFibRadios,
  useNextOpponent,
  useNextPlayer,
  UserAnswersByPrompt,
  useTallies,
  useTeams,
  whileMoving,
  quote,
} from './shared/room-data-helpers';
import { activateMove, endMove, playAgain, submitMove } from '../../api/endpoints';
import { Timer } from './shared/timer';
import { RadioGroup } from './shared/radio-group';
import { Modal } from './shared/modal';
import { TeamList } from './shared/team-list';
import { EndMoveMarquee, EndMarqueeAllPlay, GameOverMarquee } from './marquees/end-move-marquee';
import { StartMoveMarquee } from './marquees/start-move-marquee';

type GameRoomProps = {
  roomData: RoomResult;
};

export const GameRoom = ({ roomData }: GameRoomProps) => {
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [team1, team2] = useTeams(roomData.players);

  const teamUp = getTeamUp(roomData);
  const nextPrompt = getNextPrompt(roomData);
  const nextPlayer = useNextPlayer(roomData);
  const nextOpponent = useNextOpponent(team1, team2, nextPlayer);

  const {
    currentResponseCounts,
    correctAnswer,
    scoresByPlayer,
    scoresByTeam,
    userAnswersByPrompt,
    allPlayAvgByPrompt
  } = useTallies(roomData);

  // Check for All Play complete.
  useEffect(() => {
    if (!roomData.currentMove || roomData.currentMove.type !== MoveType.SLIDER_ALL_PLAY) {
      return;
    }

    if (roomData.currentMove.submitted) {
      return;
    }

    if (allPlayersResponded(roomData.currentMove.prompt.guid, userAnswersByPrompt, roomData)) {
      (async () => {
        try {
          await submitMove({
            code: roomData.code,
            promptGuid: roomData.currentMove!.prompt.guid
          });
        } catch (e) {
          console.error('could not submit choice', e);
        }
      })();
    }
  }, [roomData.currentMove?.prompt.guid, userAnswersByPrompt, roomData.players]);

  const onModalCancel = () => {
    setModalActive(false);
  };

  const onModalConfirm = async () => {
    setModalActive(false);
    try {
      const { newCode } = await playAgain({ code: roomData.code });
      window.location.href = `/room/${newCode}`;
    } catch (e) {
      console.error('could not play again', e);
    }
  };

  const isTeamUp = (teamNumber: number) => {
    if (!roomData.currentMove) {
      return false;
    }

    return teamNumber === teamUp;
  };

  const setUpMove = async () => {
    try {
      let factOrFibData: ActivateFactOrFibData | undefined;
      if (nextPrompt.type === PromptFormat.FREE_INPUT) {
        if (!nextOpponent) {
          console.error('no opponent found');
          return;
        }

        factOrFibData = {
          authorGuid: nextOpponent.user.guid
        };
      }
      await activateMove({
        code: roomData.code,
        playerGuid: nextPrompt.type === PromptFormat.SLIDER ? undefined : nextPlayer?.user.guid,
        promptGuid: nextPrompt.guid,
        duration: MoveDurationByFormat[nextPrompt.type],
        factOrFibData
      });
    } catch (e) {
      console.error('failed to activate player', e);
    }
  };

  const tearDownMove = async () => {
    if (!roomData.currentMove?.prompt) {
      console.error('no move to end');
      return;
    }

    try {
      await endMove({
        code: roomData.code,
        promptGuid: roomData.currentMove?.prompt.guid
      });
    } catch (e) {
      console.error('failed to tear down move', e);
    }
  };

  const onTimeUp = async () => {
    if (!roomData.currentMove) {
      console.error('time ran out without current move');
      return;
    }

    try {
      await submitMove({
        code: roomData.code,
        promptGuid: roomData.currentMove.prompt.guid
      });
    } catch (e) {
      console.error('could not submit choice', e);
    }
  };

  const onGameOver = () => {
    setModalActive(true);
  };

  const getTeamPanel = (teamNum: number) => {
    let className = teamNum === 1 ? 'leftTeamContainer' : 'rightTeamContainer';
    if (isTeamUp(teamNum)) {
      className += ' full';
    }

    return (
      <div className={className}>
        {teamIsMoving(teamNum, roomData)
          ? (
            <>
              {moveWasSubmitted(roomData) && !isAllPlay(roomData) && (
                <EndMoveMarquee
                  roomData={roomData}
                  correctAnswer={correctAnswer}
                  currentResponseCounts={currentResponseCounts}
                  answersByUser={userAnswersByPrompt[roomData.currentMove!.prompt.guid]}
                  onComplete={tearDownMove} />
              )}
              {moveWasSubmitted(roomData) && isAllPlay(roomData) && (
                <EndMarqueeAllPlay
                  roomData={roomData}
                  userAnswersByPrompt={userAnswersByPrompt}
                  allPlayAvgByPrompt={allPlayAvgByPrompt}
                  onComplete={tearDownMove} />
              )}
              <Timer key={'timer' + roomData.currentMove!.prompt.guid}
                initialSeconds={roomData.timeLeft! / 1000}
                hide={!whileMoving(roomData) || moveWasSubmitted(roomData)}
                onComplete={onTimeUp}
              />
              {roomData.currentMove?.type === MoveType.FREE_FACT_OR_FIB && (
                <FactOrFibPanel
                  roomData={roomData}
                  userAnswersByPrompt={userAnswersByPrompt} />
              )}
              {roomData.currentMove?.type === MoveType.MC_READ_THE_ROOM && (
                <MultiChoicePanel roomData={roomData} />
              )}
              {roomData.currentMove?.type === MoveType.SLIDER_ALL_PLAY && (
                <AllPlayPanel
                  roomData={roomData}
                  team={teamNum === 1 ? team1 : team2}
                  userAnwersByPrompt={userAnswersByPrompt}
                  name={`Team ${getTeamName(teamNum)}`} />
              )}
            </>
            )
          : (
            <>
              <h3>{`Team ${getTeamName(teamNum)}`}</h3>
              <TeamList team={teamNum === 1 ? team1 : team2} scores={scoresByPlayer} />
              <hr className='scoreDivider' />
              <p className='scoreContainer'>{`Score: ${scoresByTeam[teamNum] ?? 0}`}</p>
            </>
            )}
      </div>
    );
  };

  return (
    <>
      {isGameOver(roomData) && (
        <>
          <GameOverMarquee
            roomData={roomData}
            scoresByTeam={scoresByTeam}
            onComplete={onGameOver} />
        </>
      )}
      {isPreMove(roomData) && (
        <StartMoveMarquee
          roomData={roomData}
          nextPlayer={nextPrompt.type === PromptFormat.SLIDER ? undefined : nextPlayer}
          onComplete={setUpMove}
          nextOpponent={nextOpponent}
        />
      )}
      {getTeamPanel(1)}
      {getTeamPanel(2)}
      {!isPreMove(roomData) && isAllPlay(roomData) && (
        <div className='allPlayOverlay'>
          <h1 style={{ marginTop: '1rem' }}><u>Everyone</u></h1>
          <h3 style={{ marginTop: '1rem' }}><i>{roomData.currentMove?.prompt.text}</i></h3>
          <p>
            Give your best guess on your phone.<br />
          </p>
        </div>
      )}
      <Modal
        isActive={modalActive}
        message='Play Again?'
        confirmMessage='Yes!'
        onCancel={onModalCancel}
        onConfirm={onModalConfirm} />
    </>
  );
};

type AllPlayPanelProps = {
  roomData: RoomResult;
  team: PlayerResult[];
  userAnwersByPrompt: UserAnswersByPrompt;
  name: string;
};

const AllPlayPanel = ({ roomData, team, userAnwersByPrompt, name }: AllPlayPanelProps) => {
  const userAnswers = userAnwersByPrompt[roomData.currentMove!.prompt.guid];
  const radios = useMemo(() => (
    team.map(p => ({
      text: p.user.name,
      checked: !!userAnswers?.[p.user.guid],
      name: p.user.name
    }))
  ), [team, userAnswers]);

  return (
    <>
      <div className='allPlay'>
        <div className='allPlayTeamName'>{name}</div>
        <RadioGroup radios={radios} name={name} />
      </div>
    </>
  );
};

type FactOrFibPanelProps = {
  roomData: RoomResult;
  userAnswersByPrompt: UserAnswersByPrompt;
};

const FactOrFibPanel = ({
  roomData,
  userAnswersByPrompt
}: FactOrFibPanelProps) => {
  if (!roomData.currentMove?.factOrFib) {
    console.error('rendering fact or fib panel without data');
    return null;
  }

  const player = roomData.currentMove.player;
  const { author } = roomData.currentMove.factOrFib;

  const move = roomData.currentMove;
  const prompt = move.prompt;

  const radios = useFactOrFibRadios(roomData, userAnswersByPrompt[move.prompt.guid], roomData.currentMove?.text || null);

  if (!player) {
    console.error('fact or fib rendered without player');
    return null;
  }

  return (
    <>
      <h3 style={{ marginTop: `1rem`, fontSize: '2.2rem' }}>
        <u style={{ fontSize: '2.5rem' }}>{player.user.name}</u>:
        What did <b>{author?.user.name}</b> say when asked:
      </h3>
      <div style={{ fontSize: '1.7rem', margin: '0.8rem 0 1.2rem' }}><i>{quote(prompt.text)}</i></div>
      <hr />
      <div className='moveContainer'>
        <div className='instructions'>
          <h2>Rules:</h2>
          <ul>
            <li><b>
              {`${player.user.name} can ask any questions to opponents.`}
            </b></li>
            <li>The opponents have to answer, but they can lie!</li>
            <li>The clock is ticking!</li>
          </ul>
        </div>
        <div className='questionContainer'>
          <div className='phoneLike'>
            <p>{author?.user.name} said:</p>
            <RadioGroup radios={radios} />
          </div>
        </div>
      </div>
    </>
  );
};

type MultiChoicePanelProps = {
  roomData: RoomResult;
};

const MultiChoicePanel = ({ roomData }: MultiChoicePanelProps) => {
  const player = roomData.currentMove!.player;
  const radios = useMemo(() => {
    return roomData.currentMove?.prompt.choices?.map(c => ({
      text: c,
      checked: c === roomData.currentMove?.text
    }));
  }, [roomData.currentMove?.prompt.choices]);

  if (!player) {
    console.error('multi-choice panel rendered without player');
    return null;
  }

  return (
    <>
      <h3 style={{ margin: 0 }}><u style={{ fontSize: '3.5rem' }}>{player.user.name}</u>: Which choice was the most popular?</h3>
      <div className='moveContainer'>
        <div className='instructions'>
          <h2>Rules:</h2>
          <ul>
            <li><u>{player.user.name}</u> decides.</li>
            <li><b>Discuss with your team!</b></li>
            <li>Answer on your phone.</li>
            <li>The clock is ticking!</li>
          </ul>
        </div>
        <div className='questionContainer'>
          <div className='phoneLike'>
            <p><i>{roomData.currentMove?.prompt.text}</i></p>
            <RadioGroup radios={radios ?? []} />
          </div>
        </div>
      </div>
    </>
  );
};
