// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in;
  pointer-events: none;
  align-items: flex-start;
  z-index: 30;
}

.modalOverlay.active {
  transition-delay: 0.5s;
  opacity: 1;
  pointer-events: all;
}

.modalItself {
  margin-top: 15rem;
  background-color: var(--primary-background);
  padding: 1.5rem;
  border-radius: var(--border-radius);
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.7);
  transform: scale(0.7);
  transition: transform 0.3s linear;
  font-size: 1.7rem;
  font-weight: bold;
  text-align: center;
}

.active .modalItself {
  transform: scale(1);
  transition-timing-function: var(--bounce-effect);
}`, "",{"version":3,"sources":["webpack://./src/browser/webapp/components/room/shared/modal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,UAAU;EACV,gCAAgC;EAChC,oBAAoB;EACpB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,2CAA2C;EAC3C,eAAe;EACf,mCAAmC;EACnC,oCAAoC;EACpC,wCAAwC;EACxC,qBAAqB;EACrB,iCAAiC;EACjC,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,gDAAgD;AAClD","sourcesContent":[".modalOverlay {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  opacity: 0;\n  transition: opacity 0.3s ease-in;\n  pointer-events: none;\n  align-items: flex-start;\n  z-index: 30;\n}\n\n.modalOverlay.active {\n  transition-delay: 0.5s;\n  opacity: 1;\n  pointer-events: all;\n}\n\n.modalItself {\n  margin-top: 15rem;\n  background-color: var(--primary-background);\n  padding: 1.5rem;\n  border-radius: var(--border-radius);\n  border: 1px solid rgba(0, 0, 0, 0.3);\n  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.7);\n  transform: scale(0.7);\n  transition: transform 0.3s linear;\n  font-size: 1.7rem;\n  font-weight: bold;\n  text-align: center;\n}\n\n.active .modalItself {\n  transform: scale(1);\n  transition-timing-function: var(--bounce-effect);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
