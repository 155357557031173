import React from 'react';
import { RoomResult, PlayerResult } from '@shared/api-dto';
import { TIE } from '@shared/constants';
import { 
    useTeams,
    toUnitString,
    getAllPlayDiffs,
    getTeamName,
    UserAnswersByPrompt,
    AllPlayAverages,
    isFoFibMove,
    isMCMove,
    quote,
    AnswersByUser,
    getFarthestPlayer,
} from '../shared/room-data-helpers';
import { Marquee, MarqueeItem } from '../shared/marquee';
import { PieChart } from '../shared/pie';
import { ResponseCount, ScoresByTeamNumber } from '../shared/room-data-helpers';
import { Confetti } from '../shared/confetti';


type EndMarqueeAllPlayProps = {
  roomData: RoomResult;
  userAnswersByPrompt: UserAnswersByPrompt;
  allPlayAvgByPrompt: AllPlayAverages;
  onComplete: () => void;
};
export const EndMarqueeAllPlay = ({
  roomData, onComplete, userAnswersByPrompt, allPlayAvgByPrompt
}: EndMarqueeAllPlayProps) => {
  const [team1, team2] = useTeams(roomData.players);
  const prompt = roomData.currentMove!.prompt;
  const answers = userAnswersByPrompt[prompt.guid] ?? {};
  const [farthestPlayer, diff, avg] = getFarthestPlayer(roomData.players, answers);

  const team1Avg = toUnitString(Math.round(allPlayAvgByPrompt[prompt.guid][1]), prompt.answer?.units);
  const team2Avg = toUnitString(Math.round(allPlayAvgByPrompt[prompt.guid][2]), prompt.answer?.units);
  const [team1Diff, team2Diff] = getAllPlayDiffs(prompt, allPlayAvgByPrompt);
  let winner = 0;
  if (team1Diff < team2Diff) {
    winner = 1;
  } else if (team2Diff < team1Diff) {
    winner = 2;
  }

  const answer = roomData.currentMove?.prompt.answer?.value;
  const actualAnswer = (typeof answer === 'number') ?
    toUnitString(
      Number(roomData.currentMove?.prompt.answer?.value ?? 0),
      roomData.currentMove?.prompt.answer?.units
    ) : answer;

  const getTeamItem = (teamNum: number) => (
    <div className={`endMoveSummary team${teamNum}`}>
      {(teamNum === 1 ? team1 : team2).map(p => (
        <div className='summaryRow' key={`result_${p.user.guid}`}>
          <span>{p.user.name}</span>
          <span style={{ fontWeight: p.user.guid === farthestPlayer.user.guid ? 1000 : 'normal' }}>{
            typeof answers[p.user.guid] !== 'undefined'
              ? toUnitString(parseInt(answers[p.user.guid], 10), prompt.answer?.units)
              : '--'}</span>
        </div>
      ))}
    </div>
  );

  const getScoreItem = (teamNum: number) => (
    <div className={`endMoveSummary team${teamNum}`}>
      <p>{`${getTeamName(teamNum)} Team`}</p>
      <div className='summaryRow'>
        <span>Answer:</span>
        <span>{actualAnswer}</span>
      </div>
      <div className='summaryRow'>
        <span>Guess:</span>
        <span className='bold'>{teamNum === 1 ? team1Avg : team2Avg}</span>
      </div>
      <hr />
      <div className='summaryRow'>
        <span>Difference:</span>
        <span className='bold red'>
          {toUnitString(Math.round(teamNum === 1 ? team1Diff : team2Diff), prompt.answer?.units)}
        </span>
      </div>
    </div>
  );

  return (
    <Marquee key={'marquee_outro' + roomData.move} onComplete={onComplete}>
      <MarqueeItem duration={2500}>
        <p>Here were your answers...</p>
      </MarqueeItem>
      <MarqueeItem duration={5300}>
        <div>
          <p style={{ fontSize: '2rem' }} className='promptSummary'>
            {quote(roomData.currentMove!.prompt.text)}
          </p>
          <div className='teamSummary'>
            {getTeamItem(1)}
            {getTeamItem(2)}
          </div>
        </div>
      </MarqueeItem>
      <MarqueeItem duration={1500}>
        <p>The actual answer was...</p>
      </MarqueeItem>
      <MarqueeItem duration={2500}>
        <h1 className='actualAnswer'>{actualAnswer}</h1>
      </MarqueeItem>
      <MarqueeItem duration={2000}>
        <p>But that doesn't matter.</p>
      </MarqueeItem>
      <MarqueeItem duration={3200}>
        <p>The group average was:</p>
        <p className='actualAnswer'>{toUnitString(avg, prompt.answer?.units)}</p>
      </MarqueeItem>
      <MarqueeItem duration={2000}>
        <p>The biggest outlier was...</p>
      </MarqueeItem>
      <MarqueeItem duration={3000}>
        <p>{farthestPlayer.user.name} with:</p>
        <p className='actualAnswer'>
          {toUnitString(parseInt(answers[farthestPlayer.user.guid] ?? '0', 10), prompt.answer?.units)}
        </p>
      </MarqueeItem>
      <MarqueeItem duration={3000}>
        <p>
          for a difference of:
        </p>
        <p className='actualAnswer'>
          {toUnitString(diff, prompt.answer?.units)}
        </p>
      </MarqueeItem>
      {winner && (
        <MarqueeItem duration={3000}>
          {`-2 points to ${farthestPlayer.user.name} and the ${getTeamName(farthestPlayer.team || 1)} team!`}
        </MarqueeItem>
      )}
    </Marquee>
  );
};

type EndMoveMarqueeProps = {
  roomData: RoomResult;
  correctAnswer: string[] | undefined;
  currentResponseCounts: ResponseCount[];
  answersByUser: AnswersByUser;
  onComplete: () => void;
};

export const EndMoveMarquee = ({
  roomData,
  correctAnswer,
  currentResponseCounts,
  answersByUser,
  onComplete
}: EndMoveMarqueeProps) => {
  const player = roomData.currentMove?.player;
  if (!player || !player.team) {
    return null;
  }

  const teamName = getTeamName(player.team);
  const guess = roomData.currentMove?.text;
  const { author } = roomData.currentMove?.factOrFib ?? {};
  let prettyGuess = guess;
  let prettyAnswer = correctAnswer?.length === 1 ? correctAnswer[0] : TIE;
  if (isFoFibMove(roomData)) {
    prettyGuess = guess ? answersByUser[guess] ?? guess : '';
    prettyAnswer = author ? answersByUser[author.user.guid] ?? '' : '';
  }
  const wasCorrect = guess && correctAnswer?.includes(guess);

  return (
    <Marquee key={'marquee_outro' + roomData.move} onComplete={onComplete}>
      {prettyGuess
        ? (
          <>
            <MarqueeItem key={'player_chose' + roomData.move} duration={1250}>{`${player.user.name} chose:`}</MarqueeItem>
            <MarqueeItem key={'player_guess' + roomData.move} duration={2000}>
              <p className='answerSummary'>{quote(prettyGuess!)}</p>
            </MarqueeItem>
          </>
          )
        : (
          <MarqueeItem duration={2200}>{`${player.user.name} ran out of time`}</MarqueeItem>
          )}
      {wasCorrect
        ? (
          <MarqueeItem duration={1200}>{'That\'s CORRECT!'}</MarqueeItem>
          )
        : (
          <>
            {prettyGuess && (<MarqueeItem duration={1200}>WRONG!</MarqueeItem>)}
            {isFoFibMove(roomData) && guess && !answersByUser[guess] && (
              <MarqueeItem duration={1750}>The robot tricked you 🤖</MarqueeItem>
            )}
            <MarqueeItem duration={1200}>The answer was:</MarqueeItem>
            <MarqueeItem duration={2200}>
              {prettyAnswer === TIE
                ? prettyAnswer
                : (
                <p className='answerSummary'>
                  {quote(prettyAnswer!)}
                </p>
                  )}
            </MarqueeItem>
          </>
          )}
      {isMCMove(roomData) && (
        <MarqueeItem duration={6000}>
          <div className='pieHolder'>
            <p className='pieTitle'><i>{roomData.currentMove?.prompt.text}</i></p>
            <PieChart data={currentResponseCounts} />
          </div>
        </MarqueeItem>
      )}
      <MarqueeItem duration={2000}>
        {`${wasCorrect ? '1 point to' : 'No points for'} the ${teamName} Team`}
      </MarqueeItem>
    </Marquee>
  );
};

type GameOverMarqueeProps = {
  roomData: RoomResult;
  onComplete: () => void;
  scoresByTeam: ScoresByTeamNumber;
};

export const GameOverMarquee = ({ roomData, onComplete, scoresByTeam }: GameOverMarqueeProps) => {
  const [team1, team2] = useTeams(roomData.players);

  if (roomData.currentMove) {
    return null;
  }

  const getCongrats = (team: PlayerResult[], color: string) => (
    <div style={{ color }} className='congratsSummary'>
      <div>
        {'Congratulations '}
        <>
          {team.map((p, i) => (
            <span key={'team_' + i}>
              <b key={p.user.name}>{p.user.name}</b>
              {i < team.length - 1 ? ', ' : ''}
              {i === team.length - 2 ? ' and ' : ''}
            </span>
          ))}!!!
        </>
      </div>
    </div>
  );

  const team1Score = scoresByTeam[1] ?? 0;
  const team2Score = scoresByTeam[2] ?? 0;

  let winText = 'NOBODY! (it\'s a tie)';
  let congratsNode = <>Congratulations NOBODY!</>;
  let color = 'var(--primary-color)';
  if (team1Score > team2Score) {
    winText = 'The ' + getTeamName(1) + ' team!!!';
    color = 'var(--other-team-color)';
    congratsNode = getCongrats(team1, color);
  } else if (team2Score > team1Score) {
    winText = 'The ' + getTeamName(2) + ' team!!!';
    color = 'var(--secondary-color)';
    congratsNode = getCongrats(team2, color);
  }

  return (
    <Marquee key={'marquee_outro' + roomData.move} onComplete={onComplete}>
      <MarqueeItem duration={1500}>{'And the winner is...'}</MarqueeItem>
      <MarqueeItem duration={2000}>
        <span style={{ color }}>
          {winText}
        </span>
      </MarqueeItem>
      <MarqueeItem duration={5000}>
        {congratsNode}
        <Confetti color={team1Score > team2Score ? 'var(--other-team-color)' : 'var(--secondary-color)'} />
      </MarqueeItem>
    </Marquee>
  );
};