export enum PromptFormat {
  MULTIPLE_CHOICE = 'multiple_choice',
  FREE_INPUT = 'free_input',
  SLIDER = 'slider',
}

export const MoveDurationByFormat: Record<PromptFormat, number> = {
  [PromptFormat.MULTIPLE_CHOICE]: 32,
  [PromptFormat.FREE_INPUT]: 62,
  [PromptFormat.SLIDER]: 47,
};

export enum GuessType {
  MULTIPLE_SELECTION = '__multiple_selection__',
  FREE_TRUTH = '__free_truth__',
  FREE_LIE = '__free_lie__',
}

export enum MoveType {
  MC_READ_THE_ROOM = 'mc_read_the_room',
  FREE_FACT_OR_FIB = 'free_fact_or_fib',
  SLIDER_ALL_PLAY = 'slider_all_play',
}

export const TIE = 'It was a tie between...';

export const PromptFormatName = {
  [PromptFormat.FREE_INPUT]: 'Fact or Fib',
  [PromptFormat.MULTIPLE_CHOICE]: 'Read the Room',
  [PromptFormat.SLIDER]: 'Group Think',
};

export enum PromptAnswerUnits {
  USD = 'usd',
  PERCENTAGE = 'percentage',
  NUMBER = 'number',
  MILES = 'miles',
  FEET = 'feet',
  FAHRENHEIT = 'F',
  MPH = 'mph',
  YEARS = 'years',
  DAYS = 'days',
  MINUTES = 'minutes',
  SECONDS = 'seconds',
  GALLONS = 'gallons',
  POUNDS = 'pounds',
  WORDS = 'words',
};

export enum NetErrorType {
  KICKED = 'kicked',
}
