// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.teamList {
  flex: 1;
  list-style-type: "\\2605";
}

.teamList > li {
  margin-bottom: 0.2rem;
  text-align: left;;
}

.teamListItem {
  padding-left: 1rem;
  display: flex;
}

.teamListName {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.teamListScore {
  min-width: 7rem;
}`, "",{"version":3,"sources":["webpack://./src/browser/webapp/components/room/shared/team-list.css"],"names":[],"mappings":"AAAA;EACE,OAAO;EACP,wBAAwB;AAC1B;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".teamList {\n  flex: 1;\n  list-style-type: \"\\2605\";\n}\n\n.teamList > li {\n  margin-bottom: 0.2rem;\n  text-align: left;;\n}\n\n.teamListItem {\n  padding-left: 1rem;\n  display: flex;\n}\n\n.teamListName {\n  flex-grow: 1;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-wrap: nowrap;\n}\n\n.teamListScore {\n  min-width: 7rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
