import { PlayerResult } from '@shared/api-dto';
import React from 'react';
import { ScoresByPlayerGuid } from './room-data-helpers';

import './team-list.css';

export type TeamListProps = {
  scores: ScoresByPlayerGuid;
  team: PlayerResult[];
};

export const TeamList = ({ scores, team }: TeamListProps) => {
  return (
    <ul className='teamList'>
      {team.map(p => {
        const score = scores[p.user.guid] ?? 0;
        return (
          <li key={p.user.guid}>
            <div className="teamListItem">
              <div className="teamListName">
                {p.user.name}
              </div>
              <div className="teamListScore">
                {score} pt{score === 1 ? '' : 's'}
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
