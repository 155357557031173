// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confettiContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.confettiContainer .particle {
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/browser/webapp/components/room/shared/confetti.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,YAAY;AACd;;AAEA;EACE,yCAAyC;EACzC,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,eAAe;AACjB","sourcesContent":[".confettiContainer {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 100;\n}\n\n.confettiContainer .particle {\n  font-family: Arial, Helvetica, sans-serif;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  font-size: 3rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
