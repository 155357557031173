import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createRoom } from '../api/endpoints';

export const Landing = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const joinRef = useRef<HTMLInputElement>(null);

  const onCreate = async () => {
    try {
      setError(null);
      const data = await createRoom();
      navigate(`room/${data.code}`);
    } catch (e: any) {
      setError(e);
    }
  };

  const onJoin = () => {
    const roomCode = joinRef.current?.value;
    if (!roomCode) {
      setError('Please enter a room code to join a room');
      return;
    }
    navigate(`room/${roomCode}`);
  };

  return (
    <div className="landing">
      <h1 style={{ fontSize: '2rem' }}><img className='logo' src='/static/favicon.svg' />Welcome to Icebreak!</h1>
      <p>
        This is a game for remote teams to play together. One person will host the game and share a room code with the other players. The host will start the game and everyone will join in on their own device.
      </p>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div className="center">
        <button style={{ margin: '1.5rem 0 2rem' }} onClick={onCreate}>Start a New Game</button>
        <div>or join existing game:</div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <input ref={joinRef} type="text" placeholder="enter your room code" />
          <button className="reverse" onClick={onJoin}>Join</button>
        </div>
      </div>
    </div>
  );
};
