import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { GameRoom } from './game-room';
import { PlayerResult, PlayerTeamRequest, RoomResult, UserResult } from '@shared/api-dto';
import { useQRCode } from '../../api/hooks';
import { kickPlayer, updateTeams } from '../../api/endpoints';
import { useTallies } from './shared/room-data-helpers';

const MIN_PLAYERS = 4;

type HostRoomProps = {
  roomData: RoomResult;
};

export const HostRoom = ({ roomData }: HostRoomProps) => {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [qrError, qrDataUrl] = useQRCode(roomData.code);
  const { prePrompts } = useTallies(roomData);
  const counts = useMemo(() => {
    const result: Record<string, number> = {};
    const responses = roomData.responses;
    for (let i = 0; i < responses.length; i++) {
      const r = responses[i];
      if (!result[r.userGuid]) {
        result[r.userGuid] = 0;
      }
      ++result[r.userGuid];
    }
    return result;
  }, [roomData.responses]);

  // Game is only ready to start when 4+ players have completed prompts.
  const allPlayersReady = roomData.players.filter(p => !p.inactive).length >= MIN_PLAYERS;
  const isReady = useMemo(() => {
    // If there are no pre-prompts, just check for minimum player count
    if (prePrompts.length === 0) {
      return allPlayersReady;
    }
    // Otherwise check that enough players have completed their prompts
    return Object.values(counts).reduce((acc, completed) => (
      (completed >= prePrompts.length) ? acc + 1 : acc
    ), 0) >= MIN_PLAYERS;
  }, [counts, prePrompts.length, allPlayersReady]);

  const onStart = async () => {
    try {
      await updateTeams(roomData.code, getRandomTeams(roomData.players));
    } catch (e) {
      console.error('failed to update teams', e);
      setErrorMsg('failed to update teams');
    }
  };

  const onLeave = () => {
    navigate('/');
  };

  // Consolidate error messages.
  let finalError = '';
  if (qrError) {
    console.error(qrError);
    finalError = qrError;
  } else if (errorMsg) {
    finalError = errorMsg;
  }

  if (roomData.isActive) {
    return <GameRoom roomData={roomData} />;
  }

  return (
    <>
      <div className='leftPanel'>
        <h2>Scan QR Code to join</h2>
        {qrDataUrl
          ? (
            <img className='qr' src={qrDataUrl} />
            )
          : (
            <span className='qr'></span>
            )}
      </div>
      <div className='rightPanel'>
        {finalError && (<p className='red'>{'' + finalError}</p>)}
        <div className='playerList'>
          <p style={{ fontSize: '1.6rem' }}><u>Players</u></p>
          {roomData.players.length === 0
            ? (
              <p style={{ marginTop: '1rem' }}>Waiting for players to join.</p>
              )
            : (
              <>
                {roomData.players.map((p, i) => (
                  <div style={p.inactive ? { color: 'gray' } : {}} key={p.user.guid}>
                    <PlayerProgress
                      roomData={roomData}
                      playerNum={i + 1}
                      user={p.user}
                      counts={counts}
                      total={prePrompts.length}
                      onError={setErrorMsg}
                    />
                  </div>
                ))}
              </>
              )
          }
          {!isReady && (
            <p style={{ marginTop: '3rem' }}><i>(Must have at least 4 players ready before starting.)</i></p>
          )}
        </div>
        <div className='flex'>
          <button className='back' onClick={onLeave}>Leave</button>
          <button disabled={!isReady} onClick={onStart}>Start</button>
        </div>
      </div>
    </>
  );
};

function getRandomTeams (players: PlayerResult[]): PlayerTeamRequest[] {
  const active: PlayerResult[] = [];
  const inactive: PlayerResult[] = [];
  players.forEach(p => {
    if (p.inactive) {
      inactive.push(p);
    } else {
      active.push(p);
    }
  });

  let curTeam = true;
  const assign = (ps: PlayerResult[]): PlayerTeamRequest[] => {
    const rs: PlayerTeamRequest[] = [];
    while (ps.length > 0) {
      const i = Math.floor(Math.random() * ps.length);
      const [p] = ps.splice(i, 1);
      rs.push({
        playerGuid: p.user.guid,
        team: curTeam ? 1 : 2
      });
      curTeam = !curTeam;
    }
    return rs;
  };

  return assign(active).concat(assign(inactive));
}

type PlayerProgressType = {
  roomData: RoomResult;
  playerNum: number;
  user: UserResult;
  counts: Record<string, number>;
  total: number;
  onError: (msg: string) => void;
}

export const PlayerProgress = ({
  roomData,
  playerNum,
  user,
  counts,
  total,
  onError
}: PlayerProgressType) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const completed = counts[user.guid];
  const percentage = completed ? (completed / total) * 100 : (total / 10);
  const ready = completed === total || total === 0;

  const onKick = async () => {
    try {
      setIsLoading(true);
      await kickPlayer({
        code: roomData.code,
        playerGuid: user.guid
      });
    } catch (e: any) {
      setIsLoading(false);
      console.error('could not kick player', e);
      onError(`Could not remove ${user.name} from room`);
    }
  };

  return (
    <div className={'playerContainer' + (ready ? ' ready' : '') + (isLoading ? ' loading' : '')}>
      <div className='playerName'>
        <span>{`${playerNum}. ${user.name}`}</span>
        <span onClick={onKick} className='kickPlayer'>X</span>
      </div>
      <div className='progressContainer'>
        <div className='progressIndicator' style={{ width: `${percentage}%` }}></div>
        <div>{ready ? 'Ready!' : `${completed ?? 0} out of ${total} responses complete`}</div>
      </div>
    </div>
  );
};
